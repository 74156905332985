import photo1 from '../img/gallery/photo1.jpg';
import photo2 from '../img/gallery/photo2.jpg';
import photo3 from '../img/gallery/photo3.jpg';
import photo4 from '../img/gallery/photo4.jpg';
import photo5 from '../img/gallery/photo5.jpg';
import photo6 from '../img/gallery/photo6.jpg';
import photo7 from '../img/gallery/photo7.jpg';
import photo8 from '../img/gallery/photo8.jpg';
import photo9 from '../img/gallery/photo9.jpg';


export default [
   {
      src: photo7,
      alt: 'Evening party with puppets'
   },
   {
      src: photo8,
      x2: true,
      alt: 'Skeletons'
   },
   {
      src: photo9,
      alt: 'Zombie mask'
   },
   {
      src: photo1,
      x2: true,
      alt: 'Skull makeup'
   },
   {
      src: photo6,
      alt: 'Preparation of the afternoon party'
   },
   {
      src: photo2,
      alt: 'Ghost with lantern'
   },
   {
      src: photo4,
      alt: 'Little girl in demon costume'
   },
   {
      src: photo3,
      alt: 'Drinks'
   },
   {
      src: photo5,
      x2: true,
      alt: 'Silence'
   }
];
