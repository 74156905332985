import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import HeaderPage from '../components/HeaderPage';
import Review from '../components/Review';
import Footer from '../components/Footer';
import bg from '../img/bg.jpg';
import moon from '../img/moon.webp';
import about from '../img/about.png';
import review5 from '../img/reviews/review5.jpg';
import review6 from '../img/reviews/review6.jpg';

function Story() {
	return (
		<>
			<Helmet>
				<title>Story &bull; Sled Halloween Demo &bull; React Multipurpose Template</title>
			</Helmet>

			<HeaderPage
				bg={bg}
				pageName={'Story'}
				subtitle={'dead or undead? This is the dilemma'}
				icon={<FontAwesomeIcon icon={solid('skull')} className="badge-icon" />}
			/>

			<motion.main
				className="container"

				initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .8, ease: 'easeInOut' }}
				exit={{ opacity: 0, y: '100%' }}
			>
				<section className="mb-2">
					<h2 className="txt-center">Sled Halloween</h2>
					<h3 className="subtitle txt-center mb-2">Scares people</h3>

					<div className="grid">
						<article>
							<h2 className="mb-2">The mists of time</h2>
							<p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
							<p className="mb-2">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
							<a href="https://www.templatemonster.com/website-templates/sled-react-website-template-for-gym-restaurant-bar-and-multipurpose-template-252058.html?utm_campaign=sled_react_landing_travolgi&utm_source=travolgi&utm_medium=referral&aff=travolgi" className="badge" target="_blank" rel="noopener noreferrer">Read more</a>
						</article>

						<img src={about} alt="Orange moon" />
					</div>
				</section>

				<section className="grid gtc-x3 txt-center bg-violet rounded mb-2">
					<article>
						<i className="badge-icon mb-2">
							<FontAwesomeIcon icon={solid('skull-crossbones')} />
						</i>
						<h2>NOT ALIVE</h2>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
					</article>

					<article>
						<i className="badge-icon mb-2">
							<FontAwesomeIcon icon={solid('hat-wizard')} />
						</i>
						<h2>BLACK MAGIC</h2>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
					</article>

					<article>
						<i className="badge-icon mb-2">
							<FontAwesomeIcon icon={solid('toilet-paper')} />
						</i>
						<h2>SCARED</h2>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
					</article>
				</section>

				<section>
					<div className="grid">
						<img src={moon} className="pulse" alt="Orange moon" />

						<article>
							<h2 className="mb-2">Fearless is not fun</h2>
							<p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
							<a href="https://www.templatemonster.com/website-templates/sled-react-website-template-for-gym-restaurant-bar-and-multipurpose-template-252058.html?utm_campaign=sled_react_landing_travolgi&utm_source=travolgi&utm_medium=referral&aff=travolgi" className="badge" target="_blank" rel="noopener noreferrer">Read more</a>
						</article>
					</div>
				</section>

				<section className="grid">
					<Review
						img={review5}
						fullName="Leonardo Solem"
						stars={3}
						review="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam quis."
					/>

					<Review
						img={review6}
						fullName="Alex Turbin"
						stars={5}
						review="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam quis."
					/>
				</section>
			</motion.main>

			<Footer />
		</>
	);
}

export default Story;