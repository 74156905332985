import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import logo from '../img/logo.svg';

function Footer() {
   const thisYear = new Date().getFullYear();
   return (
      <motion.footer
         initial={{ opacity: 0, y: '100%' }}
         animate={{ opacity: 1, y: 0 }}
         transition={{ duration: 1, ease: 'easeInOut' }}
         exit={{ opacity: 0, y: '100%' }}
      >
         

         <div className="container grid gtc-2-3-1 mb-2 txt-left">
            <ul className="contact-info">
               <li>
                  <img src={logo} alt="Sled Logo" />
               </li>
               <li>
                  <FontAwesomeIcon icon={solid('location-dot')} />
                  <a href="https://www.templatemonster.com/website-templates/sled-react-website-template-for-gym-restaurant-bar-and-multipurpose-template-252058.html?utm_campaign=sledreact_landing_travolgi&utm_source=travolgi&utm_medium=referral&aff=travolgi" target="_blank" rel="noopener noreferrer">
                     <address>Your Address 1, Zip - City</address>
                  </a>
               </li>
               <li>
                  <FontAwesomeIcon icon={solid('phone')} />
                  <a href="tel:">+39 321 123456</a>
               </li>             
               <li>
                  <FontAwesomeIcon icon={solid('envelope')} />
                  <a href="mailto:">youremail@address.com</a>
               </li>
               <li>
                  <a href="https://www.templatemonster.com/website-templates/sled-react-website-template-for-gym-restaurant-bar-and-multipurpose-template-252058.html?utm_campaign=sledreact_landing_travolgi&utm_source=travolgi&utm_medium=referral&aff=travolgi" target="_blank" rel="noopener noreferrer">
                     <FontAwesomeIcon icon={brands('facebook-f')} />
                  </a>
                  <a href="https://www.templatemonster.com/website-templates/sled-react-website-template-for-gym-restaurant-bar-and-multipurpose-template-252058.html?utm_campaign=sledreact_landing_travolgi&utm_source=travolgi&utm_medium=referral&aff=travolgi" target="_blank" rel="noopener noreferrer">
                     <FontAwesomeIcon icon={brands('instagram')} />
                  </a>
                  <a href="https://www.templatemonster.com/website-templates/sled-react-website-template-for-gym-restaurant-bar-and-multipurpose-template-252058.html?utm_campaign=sledreact_landing_travolgi&utm_source=travolgi&utm_medium=referral&aff=travolgi" target="_blank" rel="noopener noreferrer">
                     <FontAwesomeIcon icon={brands('x-twitter')} />
                  </a>
               </li>
            </ul>

            <div>
               <h2>Join Our Newsletter</h2>
               <p className="mb-2">Follow our newsletter to stay updated about our events.</p>
               <form>
                  <input
                     type="email"
                     id="email"
                     name="email"
                     placeholder="Your email"
                     required
                  />
                  <button type="submit" value="submit">
                     <FontAwesomeIcon icon={solid('paper-plane')} />
                     <span className="sr-only">Subscribe Now</span>
                  </button>
               </form>
            </div>

            <nav className="mb-2">
               <h2 className="mb-2">Quick Links</h2>
               <ul>
                  <li><Link to ='/'>Home</Link></li>
                  <li><Link to='/story'>Story</Link></li>
                  <li><Link to='/party'>Party</Link></li>
                  <li><Link to ='/event-features'>Event Features</Link></li>
                  <li><Link to='/faqs'>Faqs</Link></li>
                  <li><Link to ='/where'>Where</Link></li>
                  <li><Link to ='/404'>404 page</Link></li>
               </ul>
            </nav>
         </div>

         <p>&copy; Sled Halloween {thisYear}. All Rights Reserved.</p>
         <p>Developed by <a href="https://sled.travolgi.com" target="_blank" rel="noopener noreferrer">Travolgi</a>.</p>
      </motion.footer>
   );
}

export default Footer;