import './Party.css';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import teamMembers from '../data/team';
import gallery from '../data/gallery';
import HeaderPage from '../components/HeaderPage';
import Review from '../components/Review';
import Gallery from '../components/Gallery';
import Footer from '../components/Footer';
import bg from '../img/bg2.jpg';
import street from '../img/street.jpg';
import review2 from '../img/reviews/review2.jpg';
import review3 from '../img/reviews/review3.jpg';

function Party() {
	return (
		<>
			<Helmet>
				<title>Party &bull; Sled Halloween Demo &bull; React Multipurpose Template</title>
			</Helmet>

			<HeaderPage
				bg={bg}
				pageName={'Party'}
				subtitle={'we are faceless'}
				icon={<FontAwesomeIcon icon={solid('mask')} className="badge-icon" />}
			/>

			<motion.main
				className="container"

				initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .8, ease: 'easeInOut' }}
				exit={{ opacity: 0, y: '100%' }}
			>
				<section className="txt-center">
					<h2>Learn from the best</h2>
					<h3 className="subtitle mb-2">supernatural parties</h3>

					<p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>

					<div className="grid g-2 gtc-x3">
						{teamMembers.map((team, idx) => 
							<article className="team-vertical" key={idx}>
								<img
									src={team.img}
									alt={team.name+' - Sled '+team.role}
								/>
								<h2>{team.name}</h2>
								<h3 className="subtitle">{team.role}</h3>
							</article>
						)}
					</div>
				</section>

				<section className="grid">
					<article>
						<h2 className="mb-2">Horror Trip</h2>
						<p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
						<p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
						<a href="https://www.templatemonster.com/website-templates/sled-react-website-template-for-gym-restaurant-bar-and-multipurpose-template-252058.html?utm_campaign=sled_react_landing_travolgi&utm_source=travolgi&utm_medium=referral&aff=travolgi" className="badge" target="_blank" rel="noopener noreferrer">Get the Ticket</a>

					</article>

					<img src={street} className="rounded" alt="Horror street" />
				</section>

				<section className="grid">
					<Review
						img={review2}
						fullName="James Holmes"
						stars={4}
						review="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea aliquam et sie commodo sed do eiusmod tempor."
					/>
					
					<Review
						img={review3}
						fullName="Alisha Harrison"
						stars={3}
						review="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam quis."
					/>
				</section>
			</motion.main>

			<Gallery imgs={gallery} />

			<Footer />
		</>
	);
}

export default Party;