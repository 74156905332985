import { AnimatePresence } from 'framer-motion';
import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import HomeNav from './pages/HomeNav';
import Story from './pages/Story';
import Party from './pages/Party';
import EventFeatures from './pages/EventFeatures';
import Faqs from './pages/Faqs';
import Where from './pages/Where';
import ErrorPage from './pages/ErrorPage';
import ScrollToTop from './components/ScrollToTop';
import BuyNowBtn from './components/BuyNowBtn';


export default function App() {
	const location = useLocation();
	useEffect(() => {
		setTimeout(() => window.scrollTo(0, localStorage.getItem('position')), 0);
	}, [location])

	return (
		<AnimatePresence>
			<Routes location={location} key={location.key}>
				<Route path='/' element={<HomeNav />} />
				<Route path='story' element={<Story />} />
				<Route path='party' element={<Party />} />
				<Route path='event-features' element={<EventFeatures />} />
				<Route path='faqs' element={<Faqs />} />
				<Route path='where' element={<Where />} />
				<Route path='/*' element={<ErrorPage />} />
			</Routes>

			<ScrollToTop />
			<BuyNowBtn />
		</AnimatePresence>
	);
}