import './EventFeatures.css';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import HeaderPage from '../components/HeaderPage';
import Review from '../components/Review';
import Footer from '../components/Footer';
import bg from '../img/bg3.jpg';
import food from '../img/food.jpg';
import surprise from '../img/surprise.jpg';
import outfit from '../img/outfit.jpg';
import about2 from '../img/about2.png';
import about3 from '../img/about3.png';
import review1 from '../img/reviews/review1.jpg';

function EventFeatures() {
	return (
		<>
			<Helmet>
				<title>Event Features &bull; Sled Halloween Demo &bull; React Multipurpose Template</title>
			</Helmet>

			<HeaderPage
				bg={bg}
				pageName={'Event Features'}
				subtitle={'trick or treat?'}
				icon={<FontAwesomeIcon icon={solid('wand-sparkles')} className="badge-icon" />}
			/>

			<motion.main
				className="container"

				initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .8, ease: 'easeInOut' }}
				exit={{ opacity: 0, y: '100%' }}
			>
				<section className="txt-center">
					<h2>Our Services</h2>
					<h3 className="subtitle mb-2">Warning: risk of dead</h3>
				
					<div className="grid gtc-x3 mb-2 txt-left">
						<article>
							<img src={food} className="rounded mb-2" alt="Halloween food & drink" />

							<h3 className="subtitle">Halloween</h3>
							<h2>Drink & Food</h2>
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
						</article>

						<article>
							<img src={surprise} className="rounded mb-2" alt="Surprise dead" />

							<h3 className="subtitle">Halloween</h3>
							<h2>Surprise</h2>
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
						</article>

						<article>
							<img src={outfit} className="rounded mb-2" alt="Outfit halloween custom" />

							<h3 className="subtitle">Halloween</h3>
							<h2>Outfit</h2>
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
						</article>
					</div>

					<h2>Come on!</h2>
					<p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore amet ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing elit, incididunt ut sie lotim ex labore macenates.</p>
				</section>

				<section className="grid">
					<img src={about2} className="rounded" alt="" />

					<div>
						<article>
							<i className="badge-icon mb-2">
								<FontAwesomeIcon icon={solid('skull-crossbones')} />
							</i>
							<h2>NOT ALIVE</h2>
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
						</article>
						
						<article>
							<i className="badge-icon mb-2">
								<FontAwesomeIcon icon={solid('toilet-paper')} />
							</i>
							<h2>SCARED</h2>
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
						</article>
					</div>
				</section>

				<section className="txt-center">
					<h2>Get the Ticket</h2>
					<h3 className="subtitle mb-2">Lonelly or with crew</h3>

					<p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>

               <div className="grid gtc-x3 g-2 mb-2">
						<article className="price-card">
							<h3 className="subtitle">one time</h3>
							<h2>Lonelly</h2>
							<h1>$ 90</h1>
							<ul>
								<li>Fully Responsive</li>
								<li>Mobile Friendly</li>
								<li>Clean Code Structure</li>
								<li>High Performance</li>
								<li>Semantic HTML5 markup</li>
								<li>CSS3 Custom properties</li>
								<li>W3C valid HTML files</li>
								<li>React js</li>
							</ul>
							<Link to='/where' className="badge">Get the Ticket !</Link>
						</article>

						<article className="price-card bg-violet">
							<h3 className="subtitle">one time</h3>
							<h2>Couple</h2>
							<h1>$ 130</h1>
							<ul>
								<li>Fully Responsive</li>
								<li>Mobile Friendly</li>
								<li>Clean Code Structure</li>
								<li>High Performance</li>
								<li>Semantic HTML5 markup</li>
								<li>CSS3 Custom properties</li>
								<li>W3C valid HTML files</li>
								<li>React js</li>
							</ul>
							<Link to='/where' className="badge">Get the Ticket !</Link>
						</article>

						<article className="price-card">
							<h3 className="subtitle">one time</h3>
							<h2>Crew</h2>
							<h1>$ 140</h1>
							<ul>
								<li>Fully Responsive</li>
								<li>Mobile Friendly</li>
								<li>Clean Code Structure</li>
								<li>High Performance</li>
								<li>Semantic HTML5 markup</li>
								<li>CSS3 Custom properties</li>
								<li>W3C valid HTML files</li>
								<li>React js</li>
							</ul>
							<Link to='/where' className="badge">Get the Ticket !</Link>
						</article>                  
               </div>
				</section>

				<section className="grid">
					<Review
						img={review1}
						fullName="Jessica Snow"
						stars={5}
						review="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea aliquam et sie commodo."
					/>

					<img src={about3} className="rounded" alt="Halloween Calendar October" />
				</section>
			</motion.main>

			<Footer />
		</>
	);
}

export default EventFeatures;